// src/components/StudentChatPage.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  arrayUnion,
  setDoc,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import './StudentChatPage.css';

function StudentChatPage() {
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [currentUserFirstName, setCurrentUserFirstName] = useState('');
  const [otherUserFirstName, setOtherUserFirstName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const chatRef = doc(db, 'chatRooms', chatId);

    // Fetch and subscribe to messages in real-time
    const unsubscribe = onSnapshot(chatRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const chatData = docSnapshot.data();
        setMessages(chatData.messages || []);

        // Fetch projectName and businessName if they are not already set
        if (chatData.projectName && chatData.businessName) {
          setProjectName(chatData.projectName);
          setBusinessName(chatData.businessName);
        } else {
          fetchChatDetails(chatData);
        }
      } else {
        // Initialize the chat document if it doesn't exist
        setDoc(chatRef, { messages: [] });
        fetchChatDetails();
      }
    });

    const fetchChatDetails = async (chatData = {}) => {
      try {
        const user = auth.currentUser;
        if (!user) return;

        // Fetch current user's first name
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setCurrentUserFirstName(userDoc.data().firstName);
        }

        const messagesArray = chatData.messages || [];
        let otherUserId = null;
        if (messagesArray.length > 0) {
          otherUserId = messagesArray[0].senderId !== user.uid ? messagesArray[0].senderId : null;
        }

        if (!otherUserId) {
          // If no messages yet, infer otherUserId from chatId (assuming chatId is `${businessId}_${studentId}_${projectId}`)
          const chatIdParts = chatId.split('_');
          otherUserId = chatIdParts.find((id) => id !== user.uid);
        }

        if (otherUserId) {
          // Fetch other user's first name and business name
          const otherUserDoc = await getDoc(doc(db, 'users', otherUserId));
          if (otherUserDoc.exists()) {
            setOtherUserFirstName(otherUserDoc.data().firstName);
            setBusinessName(otherUserDoc.data().businessName || 'Unnamed Business');
          }

          // Fetch application to get projectName and businessName
          const applicationsRef = collection(db, 'applications');
          const q = query(
            applicationsRef,
            where('studentId', '==', user.uid),
            where('businessId', '==', otherUserId),
            where('isCollaborating', '==', true)
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const applicationData = querySnapshot.docs[0].data();
            setProjectName(applicationData.projectName);
            setBusinessName(applicationData.businessName || 'Unnamed Business');

            // Optionally update the chatRoom document to include projectName and businessName
            await updateDoc(chatRef, {
              projectName: applicationData.projectName,
              businessName: applicationData.businessName || 'Unnamed Business',
            });
          } else {
            setProjectName('Unnamed Project');
            setBusinessName('Unnamed Business');
          }
        }
      } catch (error) {
        console.error('Error fetching chat details:', error);
      }
    };

    // Cleanup the snapshot listener on unmount
    return () => unsubscribe();
  }, [chatId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const chatRef = doc(db, 'chatRooms', chatId);

    const message = {
      senderId: auth.currentUser.uid,
      message: newMessage,
      timestamp: new Date(),
    };

    try {
      await updateDoc(chatRef, {
        messages: arrayUnion(message),
      });

      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom every time messages change
  }, [messages]);

  return (
    <div className="chat-container">
      {/* Display projectName X businessName at the top */}
      <div className="chat-header">{`${projectName} X ${businessName}`}</div>
      <div className="messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${msg.senderId === auth.currentUser.uid ? 'sender' : 'recipient'}`}
          >
            {msg.senderId !== auth.currentUser.uid && (
              <span className="avatar">{otherUserFirstName.charAt(0)}</span>
            )}
            <span className="message-content">{msg.message}</span>
            {msg.senderId === auth.currentUser.uid && (
              <span className="user-name">{currentUserFirstName}</span>
            )}
            {msg.senderId !== auth.currentUser.uid && (
              <span className="user-name">{otherUserFirstName}</span>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} /> {/* Ref to scroll to */}
      </div>
      <div className="input-container2">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="type here..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSendMessage(); // Send message on Enter
          }}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
}

export default StudentChatPage;
