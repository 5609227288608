import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './StudentChats.css';

function StudentChats() {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [studentInfo, setStudentInfo] = useState({ firstName: '', university: '' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChatsAndUserData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          // Fetch the student's firstName and university from Firestore
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setStudentInfo({
              firstName: userData.firstName,
              university: userData.university
            });
          } else {
            console.error("User document not found.");
          }

          // Fetch chats for the student
          const chatsQuery = query(collection(db, 'chatRooms'), where('studentId', '==', user.uid));
          const chatSnapshot = await getDocs(chatsQuery);
          const chatsList = chatSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data() // Spread the data from the document, including businessName, projectName, lastMessage
          }));

          setChats(chatsList);
        } else {
          console.error("No user is signed in.");
        }
      } catch (error) {
        console.error('Error fetching chats and user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchChatsAndUserData();
  }, []);

  if (loading) return <p>Loading your chats...</p>;

  return (
    <div className="chats-container2">
      {/* Display the student's firstName and university at the top */}
      <h1>{studentInfo.firstName} | {studentInfo.university}</h1>
      <h2>Collaborate</h2>

      {chats.length > 0 ? (
        chats.map(chat => (
          <div key={chat.id} className="chat-item2" onClick={() => navigate(`/student-chat/${chat.id}`)}>
            {/* Display BusinessName X ProjectName */}
            <h3>{`${chat.businessName || "Unnamed Business"} X ${chat.projectName || "Unnamed Project"}`}</h3>
            {/* Display the preview of the last message */}
          </div>
        ))
      ) : (
        <p>No chats available.</p>
      )}
    </div>
  );
}

export default StudentChats;