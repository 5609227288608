import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; // Changed BrowserRouter to HashRouter
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import SignIn from './components/auth/SignIn';
import PaymentPage from './components/stripe/PaymentPage';
import ProtectedRoute from './components/auth/ProtectedRoute';
import NavBar from './components/NavBar';
import HomePage from './pages/HomePage';
import StudentProtectedRoute from './components/auth/StudentProtectedRoute';
import BusinessProtectedRoute from './components/auth/BusinessProtectedRoute';
import ProjectBoard from './components/projects/ProjectBoard';
import BusinessDash from './pages/BusinessDash';
import AboutPage from './components/home/AboutPage';
import ApplicationsList from './components/projects/ApplicationsList';
import BusinessChatPage from './components/chat/BusinessChatPage';
import StudentChatPage from './components/chat/StudentChatPage';
import StudentChats from './components/chat/StudentChats';
import StudentDash from './pages/StudentDash';
import BusinessChats from './components/chat/BusinessChats';
import MergedSignUp from './components/auth/MergedSignUp';
import StudentProfile from './pages/StudentProfile';
import BusinessProfile from './pages/BusinessProfile';
import MyStudentProjects from './pages/MyStudentProjects';
import ConfirmOptimization from './components/projects/ConfirmOptimization';
import SetBudget from './components/projects/SetBudget';
import SuccessPage from './components/projects/SuccessPage';
import MyBusinessProjects from './components/projects/MyBusinessProjects';
import ProjectOptimization from './components/projects/ProjectOptimization';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  return (
    
    <Router>
      <NavBar />
        <Routes>
          <Route path="/upload" element={<BusinessProtectedRoute><ProjectOptimization /></BusinessProtectedRoute>} />
          <Route path="/confirm-optimization" element={<BusinessProtectedRoute><ConfirmOptimization /></BusinessProtectedRoute>} />
          <Route path="/set-budget" element={<BusinessProtectedRoute><SetBudget /></BusinessProtectedRoute>} />
          <Route path="/success" element={<BusinessProtectedRoute><SuccessPage /></BusinessProtectedRoute>} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/support" element={<AboutPage />} />
          <Route path="/learnmore" element={<AboutPage />} />
          <Route path="myprojects" element={<MyStudentProjects/>}/>
          <Route path="/signup" element={<MergedSignUp/>}/>
          <Route path="/payment" element={<ProtectedRoute><Elements stripe={stripePromise}><PaymentPage /></Elements></ProtectedRoute>} />
          <Route path="/student-dashboard" element={<StudentProtectedRoute><StudentDash /></StudentProtectedRoute>} />
          <Route path="/student-chats" element={<StudentProtectedRoute><StudentChats /></StudentProtectedRoute>} />
          <Route path="/student-profile" element={<StudentProtectedRoute><StudentProfile /></StudentProtectedRoute>} />
          <Route path="/my-projects" element={<StudentProtectedRoute><MyStudentProjects /></StudentProtectedRoute>} />
          <Route path="/project-board" element={<StudentProtectedRoute><ProjectBoard /></StudentProtectedRoute>} />
          <Route path="/business-profile" element={<BusinessProtectedRoute><BusinessProfile /></BusinessProtectedRoute>} />
          <Route path="/business-dashboard" element={<BusinessProtectedRoute><BusinessDash /></BusinessProtectedRoute>} />
          <Route path="/business-projects" element={<BusinessProtectedRoute><MyBusinessProjects /></BusinessProtectedRoute>} />
          <Route path="/applications" element={<BusinessProtectedRoute><ApplicationsList /></BusinessProtectedRoute>} />
          <Route path="/chat/:chatId" element={<BusinessProtectedRoute><BusinessChatPage /></BusinessProtectedRoute>} />
          <Route path="/business-chats" element={<BusinessProtectedRoute><BusinessChats /></BusinessProtectedRoute>} />
          <Route path="/student-chat/:chatId" element={<StudentProtectedRoute><StudentChatPage /></StudentProtectedRoute>} />
        </Routes>
    </Router>
  );
}

export default App;
