import React, { useState, useEffect } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import './PaymentPage.css';

const PaymentPage = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    budget,
    title,
    assistantResponse,
    summary,
    description,
    ownerId,
    isCollaborating,
    collaboratingBusinessId,
    studentId,
    createdAt,
  } = location.state || {};

  const [clientSecret, setClientSecret] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [country, setCountry] = useState('US');
  const [address, setAddress] = useState('');
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await fetch('/api/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ amount: budget }), // Ensure the full budget is sent
        });

        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (err) {
        console.error('Error fetching payment intent:', err);
        setError('Failed to initiate payment.');
      }
    };

    createPaymentIntent();
  }, [budget]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!clientSecret) {
      setError('Payment processing error. Missing client secret.');
      setProcessing(false);
      return;
    }

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: fullName,
              address: {
                country: country,
                line1: address,
              },
            },
          },
        }
      );

      if (error) {
        setError(`Payment failed: ${error.message}`);
        setProcessing(false);
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        setSuccess(true);
        setError(null);
        setProcessing(false);

        // Add project to Firestore
        try {
          await addDoc(collection(db, 'projects'), {
            title,
            description,
            assistantResponse,
            budget,
            summary,
            ownerId,
            isCollaborating: isCollaborating || false,
            collaboratingBusinessId: collaboratingBusinessId || null,
            studentId: studentId || ownerId,
            createdAt: createdAt || new Date(),
          });

          navigate('/success', { state: { title, budget, summary } });
        } catch (uploadError) {
          console.error('Error uploading project:', uploadError);
          alert('Failed to upload project after payment.');
        }
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('Payment processing error.');
      setProcessing(false);
    }
  };

  // Stripe Element styles
  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <div className="payment-container">
      <form onSubmit={handleSubmit}>
        <h3>Total: ${budget.toFixed(2)}</h3> {/* Display the full budget amount */}

        <label htmlFor="fullName">Full Name</label>
        <input
          type="text"
          id="fullName"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          placeholder="Full name"
          className="payment-input"
          required
        />

        <label htmlFor="country">Country</label>
        <select
          id="country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          className="payment-input"
          required
        >
          <option value="">Select Country</option>
          <option value="US">United States</option>
          <option value="CA">Canada</option>
          <option value="GB">United Kingdom</option>
          <option value="AU">Australia</option>
          {/* Add more countries as needed */}
        </select>

        <label htmlFor="address">Address</label>
        <input
          type="text"
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Street address"
          className="payment-input"
          required
        />

        <label htmlFor="cardNumber">Card Number</label>
        <div className="card-number-wrapper">
          <CardNumberElement
            className="card-element"
            options={ELEMENT_OPTIONS}
          />
        </div>

        <div className="expiry-cvc-row">
          <div className="expiry-field">
            <label htmlFor="cardExpiry">Expiration Date</label>
            <CardExpiryElement
              className="card-element"
              options={ELEMENT_OPTIONS}
            />
          </div>
          <div className="cvc-field">
            <label htmlFor="cardCvc">CVC</label>
            <CardCvcElement
              className="card-element"
              options={ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <button
          className="pay-button"
          type="submit"
          disabled={processing || success}
        >
          {processing ? 'Processing...' : `Pay $${budget.toFixed(2)}`}
        </button>
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
        <p className="secure-payment">
          🔒 All transactions are secure and encrypted.
        </p>
      </form>
    </div>
  );
};

export default PaymentPage;
