import React, { useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './MergedSignUp.css';

function SignUp() {
  const [isStudent, setIsStudent] = useState(true); // Toggle between Student and Business
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [university, setUniversity] = useState(''); // For students
  const [degree, setDegree] = useState(''); // For students
  const [year, setYear] = useState(''); // For students
  const [businessName, setBusinessName] = useState(''); // For businesses
  const [intendedProjects, setIntendedProjects] = useState(''); // For businesses
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const allowedDomains = [
    'hotmail.co.uk', 'harvard.edu', 'mit.edu', 'imperial.ac.uk', 
    'ox.ac.uk', 'cam.ac.uk', 
    'stanford.edu', 'ethz.ch', 'u.nus.edu', '@us.edu.sg', 'ucl.ac.uk', 
    'caltech.edu', 'upenn.edu', 'berkeley.edu', 'student.unimelb.edu.au', 
    'pku.edu.cn', 'e.ntu.edu.sg', 'ntu.edu.sg', 'cornell.edu', 
    'connect.hku.hk', 'hku.hk', 'sydney.edu.au', 'unsw.edu.au', 
    'mails.tsinghua.edu.cn', 'uchicago.edu', 'princeton.edu', 
    'yale.edu', 'psl.eu', 'mail.utoronto.ca', 'utoronto.ca', 
  ];

  const handleSignUp = async () => {
    console.log('SignUp function triggered!');
    setError('');
    setMessage('');
  
    // Normalize email to lowercase for case-insensitive domain check
    const normalizedEmail = email.toLowerCase();
  
    if (isStudent) {
      const domainAllowed = allowedDomains.some(domain => normalizedEmail.endsWith(domain));
      if (!domainAllowed) {
        setError('Only students from allowed universities can sign up with their university email.');
        return;
      }
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, normalizedEmail, password);
      const user = userCredential.user;
  
      const userInfo = {
        role: isStudent ? 'student' : 'business',
        firstName,
        lastName,
        roleAssigned: false,
      };
  
      if (isStudent) {
        Object.assign(userInfo, { university, degree, year });
      } else {
        Object.assign(userInfo, { businessName, intendedProjects });
      }
  
      // Store user info in Firestore
      await setDoc(doc(db, 'users', user.uid), userInfo);
  
      // Send email verification
      await sendEmailVerification(user);
  
      // Sign out the user after successful sign-up
      await auth.signOut();
  
      setMessage('Sign-up successful! Please check your email to verify your account.');
    } catch (error) {
      console.error('Error signing up:', error);
      setError('Error signing up. Please try again.');
    }
  };

  // Handle "Enter" key press to trigger form submission
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSignUp();
    }
  };
  
  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      <div className="role-selection">
        <span
          className={isStudent ? 'active' : ''}
          onClick={() => setIsStudent(true)}
        >
          Student
        </span>
        <span
          className={!isStudent ? 'active' : ''}
          onClick={() => setIsStudent(false)}
        >
          Business
        </span>
      </div>
      <div className="form-container">
        {isStudent ? (
          <>
            <div className="form-labels">
              <p>First Name</p>
              <p>Last Name</p>
              <p>University Email</p>
              <p>University</p>
              <p>Degree</p>
              <p>Year</p>
              <p>Password</p>
            </div>
            <div className="form-inputs">
              <input 
                type="text" 
                value={firstName} 
                onChange={(e) => setFirstName(e.target.value)} 
                placeholder="First Name" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
              <input 
                type="text" 
                value={lastName} 
                onChange={(e) => setLastName(e.target.value)} 
                placeholder="Last Name" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
              <input 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                placeholder="University Email" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
              <input 
                type="text" 
                value={university} 
                onChange={(e) => setUniversity(e.target.value)} 
                placeholder="University" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
              <input 
                type="text" 
                value={degree} 
                onChange={(e) => setDegree(e.target.value)} 
                placeholder="Degree" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
              <input 
                type="text" 
                value={year} 
                onChange={(e) => setYear(e.target.value)} 
                placeholder="Year" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
              <input 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                placeholder="Password" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
            </div>
          </>
        ) : (
          <>
            <div className="form-labels">
              <p>Business Name</p>
              <p>Email Address</p>
              <p>Intended Projects</p>
              <p>Password</p>
            </div>
            <div className="form-inputs">
              <input 
                type="text" 
                value={businessName} 
                onChange={(e) => setBusinessName(e.target.value)} 
                placeholder="Business Name" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
              <input 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                placeholder="Email Address" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
              <input 
                type="text" 
                value={intendedProjects} 
                onChange={(e) => setIntendedProjects(e.target.value)} 
                placeholder="Intended Projects" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
              <input 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                placeholder="Password" 
                onKeyDown={handleKeyDown} // Add keydown handler
              />
            </div>
          </>
        )}
      </div>
      <p className="send-verification" onClick={handleSignUp}>
        Send Verification
      </p>
      <p className="login-text">Already a member? <span className="login" onClick={() => navigate('/signin')}>Log In</span></p>
      {error && <p className="error-text">{error}</p>}
      {message && <p className="success-text">{message}</p>}
    </div>
  );
}

export default SignUp;
