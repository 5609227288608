// src/components/SetBudget.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SetBudget.css';
import rightarrow from '../../images/right-arrow.png';
import leftarrow from '../../images/left-arrow.png';
import Footer from '../Footer'; // Import your Footer component

const SetBudget = () => {
  const [budget, setBudget] = useState(200);
  const location = useLocation();
  const {
    title, summary, assistantResponse, description, ownerId, isCollaborating, collaboratingBusinessId, studentId, createdAt,
  } = location.state || {};
  const navigate = useNavigate();

  const increaseBudget = () => setBudget(budget + 100);
  const decreaseBudget = () => setBudget(Math.max(100, budget - 100));

  const handleNext = () => {
    navigate('/payment', {
        state: {
            title,
            summary, // Ensure summary is included here as well
            assistantResponse,
            description,
            budget,
            ownerId,
            isCollaborating,
            collaboratingBusinessId,
            studentId,
            createdAt,
        },
    });
};


  return (
    <div className="page-container">
      <div className="set-budget-container">
        <h1 className="title">Fund your project</h1>
        <div className="main-content">
          <div className="center-section">
            <span className="arrow-left" onClick={decreaseBudget}>
              <img src={leftarrow} alt="Left Arrow" className="arrow123" />
            </span>
            <h2>${budget}</h2>
            <span className="arrow-right" onClick={increaseBudget}>
              <img src={rightarrow} alt="Right Arrow" className="arrow123" />
            </span>
          </div>
          <div className="upload-button-container">
            <p className="upload-button" onClick={handleNext}>
              upload <span className="arrow">
                <img src={rightarrow} alt="Right Arrow" className="arrow123" />
              </span>
            </p>
          </div>
          <p className="note">
            This payment will be held securely in holding until you are happy that your project has been fulfilled.
            All payments are processed and held securely with Stripe.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SetBudget;