// src/components/ConfirmOptimization.js

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ConfirmOptimization.css';
import rightarrow from '../../images/right-arrow.png';
import Footer from '../Footer';

const ConfirmOptimization = () => {
  const location = useLocation();
  const {
    assistantResponse: initialAssistantResponse,
    businessProblem,
    businessField,
    description,
    summary,
    ownerId,
    isCollaborating,
    collaboratingBusinessId,
    studentId,
    createdAt,
  } = location.state || {};

  const [title, setTitle] = useState('');
  const [assistantResponse, setAssistantResponse] = useState(initialAssistantResponse || '');
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/set-budget', {
      state: {
        title,
        assistantResponse, // Pass the updated assistantResponse
        businessProblem,
        businessField,
        description,
        summary,
        ownerId,
        isCollaborating,
        collaboratingBusinessId,
        studentId,
        createdAt,
      },
    });
  };

  return (
    <div className="page-container">
      <div className="confirm-optimization-container">
        <div className="optimization-content">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Project Title"
            className="title-input"
          />
          <textarea
            value={assistantResponse}
            onChange={(e) => setAssistantResponse(e.target.value)}
            className="response-box"
          />
          <div className="fund-button" onClick={handleNext}>
            Fund
            <span className="arrow">
              <img src={rightarrow} alt="Right Arrow" className="arrow123" />
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConfirmOptimization;
