import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './HomePage.css';
import Dashboard from '../components/dashboards/Dashboard';
import HowItWorksTitle from '../components/home/HowItWorksTitle';
import JoinClub from '../components/home/JoinClub';
import Footer from '../components/Footer';
import WinWinModel from '../components/home/WinWinModel';

function HomePage() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <div className="home-page-container">
      {/* Home Page Content */}
        <div className="home-page">
          <div
            className="dashboard-container"
            style={{
              transform: `translateY(${scrollPosition / 2}px) scale(${1 - scrollPosition / 1000})`,
              opacity: `${1 - scrollPosition / 500}`,
            }}>
            <Dashboard />
          </div>
          <div className="about-us-container">
            <WinWinModel />
            <HowItWorksTitle />
          </div>
          <div className="section" data-aos="fade-up">
            <JoinClub />
          </div>
          <Footer />
        </div>
    </div>
  );
}

export default HomePage;
