import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { collection, query, where, getDoc, getDocs, doc, addDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import rightarrow from '../../images/right-arrow.png';
import leftarrow from '../../images/left-arrow.png';
import greentick from '../../images/green-tick.png'; // Import green tick
import './ApplicationsList.css';

function ApplicationsList() {
  const [applications, setApplications] = useState([]);
  const [currentApplicationIndex, setCurrentApplicationIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const [showLinks, setShowLinks] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const applicationsQuery = query(
            collection(db, 'applications'),
            where('businessId', '==', user.uid)
          );
          const applicationsSnapshot = await getDocs(applicationsQuery);
          const applicationsList = applicationsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
          setApplications(applicationsList);
        }
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };
    fetchApplications();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleNextApplication = () => {
    setCurrentApplicationIndex((prevIndex) => (prevIndex + 1) % applications.length);
  };

  const handlePreviousApplication = () => {
    setCurrentApplicationIndex((prevIndex) => (prevIndex - 1 + applications.length) % applications.length);
  };

  const handleConnect = async (application) => {
    try {
      const user = auth.currentUser;

      // Fetch the business user's data to get the businessName
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      let businessName = 'Unnamed Business';
      if (userDoc.exists()) {
        businessName = userDoc.data().businessName || 'Unnamed Business';
      }

      // Step 1: Fetch the project document using projectId
      const projectRef = doc(db, 'projects', application.projectId);
      const projectDoc = await getDoc(projectRef);

      if (projectDoc.exists()) {
        // Step 2: Update the project with collaboration details using updateDoc
        await updateDoc(projectRef, {
          isCollaborating: true,
          studentId: application.studentId,
          collaboratingBusinessId: auth.currentUser.uid,
        });

        console.log("Project updated successfully with collaboration details.");

        // Step 3: Update the corresponding application document
        const applicationRef = doc(db, 'applications', application.id); // Ensure correct doc ID
        await updateDoc(applicationRef, {
          isCollaborating: true,
        });

        console.log("Application updated with collaboration details.");

        // Step 4: Check if a chat room already exists
        const chatRoomQuery = query(
          collection(db, 'chatRooms'),
          where('businessId', '==', auth.currentUser.uid),
          where('studentId', '==', application.studentId),
          where('projectId', '==', application.projectId)
        );

        const chatRoomSnapshot = await getDocs(chatRoomQuery);

        if (chatRoomSnapshot.empty) {
          await addDoc(collection(db, 'chatRooms'), {
            businessId: auth.currentUser.uid,
            studentId: application.studentId,
            projectId: application.projectId,
            businessName: businessName, // Include businessName
            studentName: application.studentName,
            projectName: application.projectName,
            createdAt: new Date(),
            messages: [],
          });
        }

        // Step 5: Update the state to reflect collaboration in the UI
        setApplications((prevApplications) =>
          prevApplications.map((app) =>
            app.id === application.id ? { ...app, isCollaborating: true } : app
          )
        );
        console.log("Application updated locally with collaboration details.");
      } else {
        console.error('Project not found!');
      }
    } catch (error) {
      console.error('Error creating or accessing chat room or updating project:', error);
    }
  };

  if (applications.length === 0) {
    return <p>No applications yet.</p>;
  }

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  const currentApplication = applications[currentApplicationIndex];

  return (
      <div className={`dashboard-container3 ${loaded ? 'loaded' : ''}`}>
        <div className="upper-section3">
          <div className="dashboard-header3">
            <h1 onClick={() => navigate('/business-dashboard')}>Dashboard</h1>
            <h1 className="business3">Company</h1>
            <div className="applications-menu">
              <h1 className="home3" onClick={toggleLinks}>Applications</h1>
              {showLinks && (
                <div className="additional-links3">
                  <p onClick={() => navigate('/business-profile')}>account</p>
                  <p onClick={() => navigate('/business-projects')}>my projects</p>
                  <p onClick={() => navigate('/applications')}>applications</p>
                  <p onClick={() => navigate('/business-chats')}>collaborate</p>
                  <p onClick={() => navigate('/support')}>support</p>
                  <p onClick={() => auth.signOut()}>log out</p>
                </div>
              )}
            </div>
          </div>
          <div className="applications-content">
            <div className="applications-container1">
              <h2>Assess Candidates</h2>
            </div>
            <div className="application">
            <div className="arrow-left" onClick={handlePreviousApplication}>
              <img src={leftarrow} alt="Arrow" className="arrow" />
            </div>
            <div className="application-info">
              <h3 className="student-name">{currentApplication.studentName}</h3>
              <p className="university-name">{currentApplication.university}</p>
              <p className="degree-info">
                {currentApplication.studentName} studies {currentApplication.degree} and is a {currentApplication.year} year student.
              </p>
              <p className="project-name">Project: {currentApplication.projectName}</p>
              {currentApplication.isCollaborating ? (
                <div className="collaboration-section">
                  <img src={greentick} alt="Green Tick" className="green-tick" />
                  <p className="collaborate-text" onClick={() => navigate(`/business-chats`)}>Collaborate</p>
                </div>
              ) : (
                <button onClick={() => handleConnect(currentApplication)}>Offer</button>
              )}
            </div>
            <div className="arrow-right" onClick={handleNextApplication}>
              <img src={rightarrow} alt="Right Arrow" className="arrow" />
            </div>
            </div>
            <div className="dots">
            {applications.map((_, index) => (
              <span key={index} className={`dot ${index === currentApplicationIndex ? 'active' : ''}`}></span>
            ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default ApplicationsList;
