import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutPage.css';

const AboutPage = () => {

  const navigate = useNavigate();

  return (
    <div className="aboutuscontainer12">
    <div className="about-page">
      <h2>This page is currently under construction.</h2>
    </div>
          <div className="links1234">
          <button className="link-button1234" onClick={() => navigate('/')}>
            home
          </button>
          <button className="link-button1234" onClick={() => navigate('/signup')}>
            sign up
          </button>
        </div>
        </div>
  );
};

export default AboutPage;
