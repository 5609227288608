// src/components/HowItWorksTitle.js
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './HowItWorksTitle.css';
import ImageCarousel from './ImageCarousel';
import ImageCarouselCompany from './ImageCarouselCompany';

function HowItWorksTitle() {
  const [scrollPosition, setScrollPosition] = useState(0);
  
  useEffect(() => {
    
    AOS.init({ duration: 1000 });

    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <div className="carousel2" data-aos="fade-up">
        <ImageCarouselCompany/>
      </div>
      <div className="carousel1" data-aos="fade-up">
        <ImageCarousel />
      </div>

      </div>
  );
};

export default HowItWorksTitle;
