import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import './BusinessChats.css';

function BusinessChats() {
  const [chats, setChats] = useState([]);
  const [businessName, setBusinessName] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          await fetchBusinessName(user);
          await fetchChats(user);
        } else {
          console.error('No user is signed in.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchBusinessName = async (user) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.role === 'business') {
          setBusinessName(userData.businessName || '');
        } else {
          console.error('User is not a business.');
        }
      } else {
        console.error('No user document found.');
      }
    } catch (error) {
      console.error('Error fetching business name:', error);
    }
  };

  const fetchChats = async (user) => {
    try {
      const chatsQuery = query(
        collection(db, 'chatRooms'),
        where('businessId', '==', user.uid)
      );
      const chatSnapshot = await getDocs(chatsQuery);
      const chatsList = chatSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setChats(chatsList);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  if (loading) return <p>Loading your chats...</p>;

  return (
    <div className="chats-container">
      <h1>{businessName}</h1>
      <h2>Collaborate</h2>
      <div className="chat-items-container">
        {chats.length > 0 ? (
          chats.map((chat) => (
            <div
              key={chat.id}
              className="chat-item"
              onClick={() => navigate(`/chat/${chat.id}`)}
            >
              <h3>{`${chat.studentName || 'Unnamed Student'} X ${
                chat.projectName || 'Unnamed Project'
              }`}</h3>
              <p>{chat.lastMessage || 'No messages yet'}</p>
            </div>
          ))
        ) : (
          <p>No chats available.</p>
        )}
      </div>
      <Footer />
    </div>
  );
  
}

export default BusinessChats;
