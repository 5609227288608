import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getIdTokenResult } from 'firebase/auth';
import './StudentProfile.css';
import Footer from '../components/Footer';
import rightarrow from '../images/right-arrow.png';

const StudentProfile = () => {
  const [loaded, setLoaded] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [userRole, setUserRole] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // To toggle edit mode
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    pastProjects: ''
  }); // Form state for editable inputs
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Fetch user role
          const idTokenResult = await getIdTokenResult(user);
          setUserRole(idTokenResult.claims.role);

          // Fetch user details from Firestore
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserDetails(data);
            setFormData({
              firstName: data.firstName || '',
              lastName: data.lastName || '',
              pastProjects: data.pastProjects || 'N/A'
            });
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setUserRole(null);
        setUserDetails({});
      }
    });

    return () => unsubscribe();
  }, []);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  const handleDashboardClick = () => {
    if (userRole === 'business') {
      navigate('/business-dashboard');
    } else if (userRole === 'student') {
      navigate('/student-dashboard');
    } else {
      // If the role is not defined, fallback to a default page
      navigate('/');
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing); // Toggle between edit and view mode
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSaveChanges = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          firstName: formData.firstName,
          lastName: formData.lastName,
          pastProjects: formData.pastProjects
        });
        setUserDetails(formData);
        setIsEditing(false); // Exit edit mode after saving
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div className={`dashboard-container ${loaded ? 'loaded' : ''}`}>
      <div className={`upper-section ${showLinks ? 'show-links' : ''}`}>
        <div className="dashboard-header">
          <h1>Dashboard</h1>
          <h1 className="student">Student</h1>
          <h1 className="home" onClick={toggleLinks}>account</h1>
          {showLinks && (
            <div className="additional-links">
              <p onClick={handleDashboardClick}>home</p>
              <p>my projects</p>
              <p onClick={() => navigate('/project-board')}>project board</p>
              <p onClick={() => navigate('/student-chats')}>collaborate</p>
              <p onClick={() => navigate('/support')}>support</p>
              <p onClick={() => auth.signOut()}>log out</p>
            </div>
          )}
        </div>

        <div className="account-details">
          <div className="user-details">
            <div className="details-labels">
              <p>student name</p>
              <p>email address</p>
              <p>past projects</p>
            </div>

            <div className="details-values">
              {/* Toggle between edit and view mode */}
              {isEditing ? (
                <>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                  />
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                  />
                  <input
                    type="text"
                    name="pastProjects"
                    value={formData.pastProjects}
                    onChange={handleInputChange}
                    placeholder="Past Projects"
                  />
                  <button onClick={handleSaveChanges}>Save Changes</button>
                </>
              ) : (
                <>
                  <p>{userDetails.firstName} {userDetails.lastName}</p>
                  <p>{auth.currentUser?.email}</p>
                  <p>{userDetails.pastProjects || 'N/A'}</p>
                  <button onClick={handleEditToggle}>Edit Profile</button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-links-container2">
        <div className="dashboard-links2">
          <span onClick={handleDashboardClick}>home <span className="arrow">
          <img src={rightarrow} alt="Right Arrow" className="arrow123" />
            </span></span>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default StudentProfile;
