// src/components/ProjectOptimization.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import './ProjectOptimization.css';
import rightarrow from '../../images/right-arrow.png';
import Footer from '../Footer';

const API_BASE_URL = process.env.NODE_ENV === 'production' 
    ? ''
    : 'http://localhost:5001';

const ProjectOptimization = () => {
  const [businessProblem, setBusinessProblem] = useState('');
  const [businessField, setBusinessField] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to auto-resize textareas
  const autoResize = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
  };


  const handleOptimize = async () => {
    setLoading(true);
    try {
        const response = await fetch(`${API_BASE_URL}/api/upload`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ businessProblem, businessField, description }),
        });
        const data = await response.json();
        const assistantResponse = data.assistantResponse || 'No response received.';
        
        // Generate the summary based on the assistant's response or other fields
        const summary = assistantResponse.substring(0, 100); // Example logic to create a short summary
        
        const ownerId = auth.currentUser?.uid || '';
        const isCollaborating = false;
        const collaboratingBusinessId = null;
        const studentId = ownerId;
        const createdAt = new Date();

        // Navigate to the confirmation page with all necessary fields
        navigate('/confirm-optimization', {
            state: {
                assistantResponse,
                businessProblem,
                businessField,
                description,
                summary, // Include the summary here
                ownerId,
                isCollaborating,
                collaboratingBusinessId,
                studentId,
                createdAt,
            },
        });
    } catch (error) {
        console.error('Error processing optimization:', error);
    } finally {
        setLoading(false);
    }
};


  return (
    <div className="project-optimization-container">
      <h2 className="title">Upload Your Project</h2>
      <div className="main-content">
        <input
          className="input-box"
          type="text"
          value={businessField}
          onChange={(e) => setBusinessField(e.target.value)}
          placeholder="Business field"
        />
        <textarea
          className="input-box"
          value={businessProblem}
          onChange={(e) => setBusinessProblem(e.target.value)}
          onInput={autoResize}
          placeholder="Describe your business problem"
        />
        <textarea
          className="input-box"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onInput={autoResize}
          placeholder="Describe your project"
        />
        <p onClick={handleOptimize} className={`submit-button ${loading ? 'loading' : ''}`}>
          {loading ? (
            <>
              Processing<span className="spinner"></span>
            </>
          ) : (
            <>
              Submit <span className="arrow">
                <img src={rightarrow} alt="Right Arrow" className="arrow123" />
              </span>
            </>
          )}
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectOptimization;