// src/components/ImageCarousel.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation'; // Import Swiper navigation styles
import { Navigation } from 'swiper/modules'; // Import Navigation module from Swiper
import './ImageCarousel.css'; // Custom styles
import student1 from '../../images/student1.png';
import student2 from '../../images/student2.png';
import student3 from '../../images/student3.png';
import student4 from '../../images/student4.png';
import student5 from '../../images/student5.png';
import student6 from '../../images/student6.png';

const images = [
  { id: 1, image: student1 },
  { id: 2, image: student2 },
  { id: 3, image: student3 },
  { id: 4, image: student4 },
  { id: 5, image: student5 },
  { id: 6, image: student6 },
];

const ImageCarousel = () => {
  return (
    <div className="image-carousel">
     <p>For Students</p>
     <Swiper
        spaceBetween={30}
        slidesPerView={4} // Default for desktop
        navigation={true} // Enables navigation arrows
        modules={[Navigation]}
        breakpoints={{
          0: {
            slidesPerView: 1.5, // 1 full slide on mobile
            centeredSlides: true, // Center the slide
            spaceBetween: 10, // Smaller space between slides on mobile
          },
          768: {
            slidesPerView: 4, // Show 4 slides on desktop
            spaceBetween: 30, // Wider spacing between slides on desktop
          },
        }}
      >
        {images.map((image) => (
          <SwiperSlide key={image.id}>
            <div className="image-card">
              <img src={image.image} alt={`Slide ${image.id}`} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageCarousel;
